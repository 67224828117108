import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
@Component({
  selector: 'app-operasional',
  templateUrl: './operasional.page.html',
  styleUrls: ['./operasional.page.scss'],
})
export class OperasionalPage implements OnInit {

    constructor(
        public modalController : ModalController
    ) { }

    @Input() keterangan: string;
    data: any;
    ngOnInit() {
        this.data = [];
        let ket = this.keterangan;
        let i = 0;
        let dt = ket.split("|");
        for(i=0;i<dt.length;i++){
           
            this.data.push({
                nama : dt[i]
            });
        }
    }
    dismiss() {
        this.modalController.dismiss({
          'dismissed': true
        });
    }

}
