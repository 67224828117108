import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NavController } from '@ionic/angular';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.page.html',
  styleUrls: ['./calendar.page.scss'],
})

export class CalendarPage implements OnInit {

    constructor(
        private navController: NavController,
        public router: Router,
        public activatedRoute: ActivatedRoute,
        public modalController : ModalController
    ) { }

    ngOnInit() {
        
    }
    dismiss() {
        this.modalController.dismiss({
        'dismissed': true
        });
    }

}
