import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
@Component({
  selector: 'app-consent',
  templateUrl: './consent.page.html',
  styleUrls: ['./consent.page.scss'],
})
export class ConsentPage implements OnInit {

    constructor(public modalController : ModalController) { }

    ngOnInit(){

    }
    
	dismiss() {
	    this.modalController.dismiss({
	      'lanjut': 0
	    });
	}

    lanjut(){
        this.modalController.dismiss({
            'lanjut':1
        });
    }



}
