import { Component, OnInit } from '@angular/core';
import { HealthcareService } from "../../../service/healthcare.service";
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-lokasi',
  templateUrl: './lokasi.page.html',
  styleUrls: ['./lokasi.page.scss'],
})
export class LokasiPage implements OnInit {
    filterTerm: string;
    lokasi : any;
    cari:any;
    produk_dipilih:any;
    constructor(
        private healthcareService: HealthcareService,
        public modalController : ModalController
    ) { }
    
    ngOnInit() {
        this.cari = '';
        this.loadData();
    }

    loadData(){
        this.produk_dipilih = localStorage.getItem('pid_pilih');
        let cari = this.cari;
        this.healthcareService.showLoading();
        this.lokasi = [];
        this.healthcareService.getLokasi(this.produk_dipilih,cari).subscribe(
            data => {
                this.healthcareService.hideLoading();
                var res = JSON.parse(JSON.stringify(data));
                if (data.hasOwnProperty('result')) {
                    
                    var xdata = res.result.data;
                    var z = 0;
                    for (z = 0; z < xdata.length; z++) {
                         
                        this.lokasi.push({
                            kota: xdata[z].kota,
                            propinsi: xdata[z].propinsi,
                            kecamatan: xdata[z].kecamatan,
                            text: xdata[z].kecamatan+' - '+xdata[z].kota,
                        });
                    }
                    console.log(this.lokasi);
                }else {
                    this.healthcareService.showToast(res.error.message);
                }
            },
            error => {
                this.healthcareService.hideLoading();
                this.healthcareService.showToast('Error Connection');
            }
        );
    }

    dismiss() {
        this.modalController.dismiss({
          'dismissed': true
        });
    }

    pilihData(data){
        console.log(data);
        this.modalController.dismiss(data);
    }

    cariData(){
        let cari = (document.getElementById('cari') as HTMLInputElement).value;
        this.cari = cari;
        this.loadData();
    }
}
